import React from "react";
import "./Services.scss";
import { BannerPresentation } from "../../components/BannerPresentation/BannerPresentation";
import { ServicesDescriptionParagraph } from "../components/ServicesDescriptionParagraph/ServicesDescriptionParagraph";
import { Gallery } from "../Gallery/Gallery";
import Card from "../../components/Card/Card";
import { servicesList } from "./ServicesList";
import CardLong from "../../components/CardLong/CardLong";

export const Services = () => {
  return (
    <div>
      <BannerPresentation text="Services" ima="/images/servicesBanner.jpg" />
      <div className="services-description-section">
        <div className="col-left-services-description">
          <CardLong />
        </div>
        <div className="col-right-services-description">
          <div>
            <h2>What characterizes us...</h2>
          </div>
          <ServicesDescriptionParagraph
            title="BUILD WITH CONFIDENCE"
            text="With over 10 years’ experience, WOODCO BUILDING is your partner for
          quality renovations, additions, kitchens, bathrooms and new builds in
          Sydney’s Inner West and Lower North Shore.
          
          We’re ready to work with your chosen architect or we can find one that’s
          right for your project. You can count on us to manage your build from
          start to finish with attention to detail that’s second to none."
          />
          <ServicesDescriptionParagraph
            title="ADDING VALUE, NOT STRESS"
            text="We know any build is a big investment. But it needn’t be stressful. We want
          you to enjoy seeing your dream a reality."
          />
          <ServicesDescriptionParagraph
            title="ON TIME, ON BUDGET"
            text="We’re proud of our project management style that’s all about your needs.
          We’re flexible and keep you up-to-date on the process from start to finish."
          />
          <ServicesDescriptionParagraph
            title="FROM OUR FAMILY TO YOURS"
            text="Family is at the centre of everything we do. If it’s important to you, it’s
          important to us."
          />
        </div>
      </div>

      <div className="services-description-section-mobile">
        <Card
          className="card card-presentation-1"
          ima="/images/blankBuild.jpg"
          title=""
          description=""
        />

        <ServicesDescriptionParagraph
          title="BUILD WITH CONFIDENCE"
          text="With over 10 years’ experience, WOODCO BUILDING is your partner for
          quality renovations, additions, kitchens, bathrooms and new builds in
          Sydney’s Inner West and Lower North Shore.
          <br />
          We’re ready to work with your chosen architect or we can find one that’s
          right for your project. You can count on us to manage your build from
          start to finish with attention to detail that’s second to none."
        />

        <ServicesDescriptionParagraph
          title="ADDING VALUE, NOT STRESS"
          text="We know any build is a big investment. But it needn’t be stressful. We want
          you to enjoy seeing your dream a reality."
        />

        <Card
          className="card card-presentation-1"
          ima="/images/blankBuild.jpg"
          title=""
          description=""
        />

        <ServicesDescriptionParagraph
          title="ON TIME, ON BUDGET"
          text="We’re proud of our project management style that’s all about your needs.
          We’re flexible and keep you up-to-date on the process from start to finish."
        />
        <ServicesDescriptionParagraph
          title="FROM OUR FAMILY TO YOURS"
          text="Family is at the centre of everything we do. If it’s important to you, it’s
          important to us."
        />
      </div>
      <div>
        <div className="subtitle-container-services">
          <h2>WE WOULD LOVE TO WORK ON YOUR NEXT PROJECT!</h2>
        </div>
        <div className="services-cards-container">
          {servicesList.map((item) => {
            return (
              <Card
                className={"card " + item.id}
                ima={item.ima}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
      <a id="gallery"></a>
      <Gallery />
    </div>
  );
};
