import React from "react";
import "./GalleryBanner.scss";

export const GalleryBanner = ({ ima, text }) => {
  return (
    <section
      className="banner-gallery-container"
      style={{
        background: `url(${ima})`,
        backgroundSize: "cover",
        backgroundPositionY: "center",
      }}
    >
      <div className="border-vertical-container">
        <div className="circle-container">
          <h1 className="banner-presentation-title">OUR GALLERY</h1>
          <p>
            These are some of our works, we hope you like them and are encou-
            raged to work with us...
          </p>
        </div>
      </div>
    </section>
  );
};
