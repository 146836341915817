import React from "react";
import { BannerPresentation } from "../../components/BannerPresentation/BannerPresentation";
import { SectionSlider } from "../components/SectionSlider/SectionSlider";
import "./About.scss";

export const About = () => {
  return (
    <>
      <section>
        <BannerPresentation text="About Us" ima="/images/aboutBanner.jpg" />
        <div className="about-description-container">
          <p>
            Here at Woodco Building Services, we strive to be the best home
            builders in the business, with the highest standards and the best
            customer service.
          </p>
          <p>
            We give honest advice and treat every single one of our clients as
            if they were part of our own family.
          </p>
          <p>
            We have found the secret to success is within not only our craft but
            our relationship with you, the client.
          </p>
        </div>
        <div className="about-list-container">
          <div className="one">
            <h3 className="text-list-about">
              <strong>1.</strong> General Contracting
            </h3>
          </div>
          <div className="two">
            <h3 className="text-list-about">
              <strong>5.</strong> Sustainable building
            </h3>
          </div>
          <div className="three">
            <h3 className="text-list-about">
              <strong>2.</strong> Owner’s Project Managment
            </h3>
          </div>
          <div className="four">
            <h3 className="text-list-about">
              <strong>6.</strong> Design Collaboration
            </h3>
          </div>
          <div className="five">
            <h3 className="text-list-about">
              <strong>3.</strong> Residential & Commercial Carpentry
            </h3>
          </div>
          <div className="six">
            <h3 className="text-list-about">
              <strong>7.</strong> Ecological Restoration
            </h3>
          </div>
          <div className="seven">
            <h3 className="text-list-about">
              <strong>4.</strong> Residential & Commercial Excavation
            </h3>
          </div>
          <div className="eight">
            <h3 className="text-list-about">
              <strong>8.</strong> In time project’s building
            </h3>
          </div>
        </div>
      </section>
      <section>
        <BannerPresentation
          text="Who We Are..."
          ima="/images/bannerStears.jpg"
        />
        <SectionSlider />
      </section>
    </>
  );
};
