import React from "react";
import { BigSectionSlider } from "../components/BigSectionSlider/BigSectionSlider";
import { GridHomePresentation } from "../components/GridHomePresentation/GridHomePresentation";

export const Home = () => {
  return (
    <div>
      <BigSectionSlider />
      <GridHomePresentation/>
    </div>
  );
};
