export const data = [
  {
    ima: "/gallery/1.jpg",
    alt: "",
    id: "1",
    address: "48/6 Herbert Street,St Leonards, 2065, Ryde, NSW",
  },
  {
    ima: "/gallery/2.jpg",
    alt: "",
    id: "2",
    address: "48/6 Herbert Street,St Leonards, 2065, Ryde, NSW",
  },
  {
    ima: "/gallery/3.jpg",
    alt: "",
    id: "3",
    address: "48/6 Herbert Street,St Leonards, 2065, Ryde, NSW",
  },
  {
    ima: "gallery/4.jpg",
    alt: "",
    id: "4",
    address: "48/6 Herbert Street,St Leonards, 2065, Ryde, NSW",
  },
  {
    ima: "/gallery/5.jpg",
    alt: "",
    id: "5",
    address: "48/6 Herbert Street,St Leonards, 2065, Ryde, NSW",
  },
  {
    ima: "/gallery/6.jpg",
    alt: "",
    id: "6",
    address: "48/6 Herbert Street,St Leonards, 2065, Ryde, NSW",
  },
  {
    ima: "/gallery/7.jpg",
    alt: "",
    id: "7",
    address: "48/6 Herbert Street,St Leonards, 2065, Ryde, NSW",
  },
  {
    ima: "/gallery/8.jpg",
    alt: "",
    id: "8",
    address: "48/6 Herbert Street,St Leonards, 2065, Ryde, NSW",
  },
  {
    ima: "/gallery/9.jpg",
    alt: "",
    id: "9",
    address: "48/6 Herbert Street,St Leonards, 2065, Ryde, NSW",
  },
];
