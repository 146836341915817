import React from "react";
import { BannerPresentation } from "../../components/BannerPresentation/BannerPresentation";
import { FormButton } from "../../components/Buttons/Buttons";
import "./Contact.scss";

export const Contact = () => {
  const gmapUrl =
    "https://maps.google.com/maps?q=48/6%20Herbert%20St,%20St%20Leonards%20NSW%202065,%20Australia&t=&z=15&ie=UTF8&iwloc=&output=embed";

  return (
    <section>
      <BannerPresentation text="Contact Us" ima="/images/contactBanner.jpg" />
      <div className="contact-description-container">
        <p>
          When you're ready to talk be sure to contact us either by email or
          telephone at any time <br /> We're happy to help with your enquiries
          and look forward to your call.
          <br />
          <br />
          Or send us a message below and we'll be in contact as soon as
          possible.
        </p>
      </div>
      <div className="contact-info-container">
        <div className="one">
          <img
            className="icons-contact-us"
            src="/icons/WoodcoContactUs1.png"
            alt="icon-location"
          />
          <div className="text-adres-container">
            <p>48/6 Herbert Street,</p>
            <p>St Leonards, 2065, Ryde, NSW</p>
          </div>
        </div>
        <div className="two">
          <img
            className="icons-contact-us"
            src="/icons/WoodcoContactUs2.png"
            alt="icon-phone"
          />
          <p>0405804382</p>
        </div>
        <div className="three">
          <img
            className="icons-contact-us"
            src="/icons/WoodcoContactUs3.png"
            alt="icon-mail"
          />
          <p>info@woodcobuilding.com.au</p>
        </div>
      </div>
      <div className="form-section-container">
        <form
          action="https://formsubmit.co/Info@woodcobuilding.com.au"
          method="POST"
        >
          <div className="form-items-container">
            <div className="myform-item">
              <p>First name*</p>
              <input
                className="contact-input-form"
                placeholder="Jon "
                type="text"
                name="first Name"
                required
              />
            </div>

            <div className="myform-item">
              <p>Last Name*</p>
              <input
                className="contact-input-form"
                placeholder="Doe"
                type="text"
                name="last Name"
                required
              />
            </div>

            <div className="myform-item">
              <p>Phone*</p>
              <input
                className="contact-input-form"
                placeholder="000-000-0000"
                type="number"
                name="phone"
                required
              />
            </div>

            <div className="myform-item">
              <p>Email*</p>
              <input
                className="contact-input-form"
                placeholder="jondoe@example.com"
                type="email"
                name="email"
                required
              />
            </div>

            <div className="myform-item">
              <p>Adress</p>
              <input
                className="contact-input-form"
                placeholder="123 street example"
                type="text"
                name="adress"
                required
              />
            </div>

            <div className="myform-item">
              <p>City</p>
              <input
                className="contact-input-form"
                placeholder="Sydney"
                type="text"
                name="city"
                required
              />
            </div>
          </div>

          <div className="myform-item">
            <p>How did you hear about us?</p>
            <input
              className="contact-input-form"
              placeholder="Social Media"
              type="text"
              name="how-did-you-hear"
              required
            />
          </div>

          <FormButton type="submit" text="Submit Form" click={() => {}} />
        </form>
      </div>
      <div className="map-container">
        <div className="mapWrapper">
          <div className="gmapCanvas">
            <iframe
              title="woodcoMap"
              className="mapCanvas"
              id="gmap_canvas"
              src={gmapUrl}
              frameBorder="0"
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
            ></iframe>
            <br />
          </div>
        </div>
      </div>
    </section>
  );
};
