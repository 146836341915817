import React from "react";

import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import MobileMenu from "../MobileMenu/MobileMenu";
import "./NavBar.scss";

// declare function Link(props: LinkProps): React.ReactElement;

export const NavBar = () => {
  const menuItems = [
    { id: "1", name: "Home", link: "/home" },
    { id: "2", name: "About Us", link: "/About" },
    { id: "3", name: "Services" },
    { id: "4", name: "Lates News" },
    { id: "5", name: "Contact Us" },
  ];

  return (
    <div className="navbarContainer">
      <div className="navbar-col-left-container">
        <img
          className="navbar-white-logo"
          src="/icons/woodcoWhiteLogo.png"
          alt="logo-woodco"
        />
      </div>

      <ul className="menu">
        <NavLink
          style={({ isActive }) => ({
            color: isActive ? "#8A8478" : "white",
            textDecoration: isActive ? "underline" : "none",
          })}
          className="menu-navbar-text link-menu"
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          style={({ isActive }) => ({
            color: isActive ? "#8A8478" : "white",
            textDecoration: isActive ? "underline" : "none",
          })}
          className="menu-navbar-text link-menu"
          to="/About"
        >
          About Us
        </NavLink>
        <NavLink
          style={({ isActive }) => ({
            color: isActive ? "#8A8478" : "white",
            textDecoration: isActive ? "underline" : "none",
          })}
          className="menu-navbar-text link-menu"
          to="/Services"
        >
          Services
        </NavLink>
        <HashLink className="menu-navbar-text link-menu" to="/services#gallery">
          Gallery
        </HashLink>
        <NavLink
          style={({ isActive }) => ({
            color: isActive ? "#8A8478" : "white",
            textDecoration: isActive ? "underline" : "none",
          })}
          className="menu-navbar-text link-menu"
          to="/Contact-Us"
        >
          Contact Us
        </NavLink>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center"
            
          }}
        >
          <a
            href="https://www.instagram.com/woodco.building/?hl=en"
            target="_blank"
          >
            <img
              alt="instagram-icon"
              src="/icons/instagram.png"
              width={20}
              height={20}
              style={{marginRight: "20px"}}
            />
          </a>

          <a
            href="https://www.facebook.com/WoodCoBuildingPtyLtd"
            target="_blank"
          >
            <img
              alt="facebook-icon"
              src="/icons/facebook.png"
              width={20}
              height={20}
              style={{ backgroundColor: "white", borderRadius: "8px" }}
            />
          </a>
        </div>
      </ul>
      <MobileMenu />
    </div>
  );
};

/*
<li>Home</li>
        <li>About Us</li>
        <li>Services</li>
        <li>Lates News</li>
        <li>Contact Us</li>
        <li>El otro</li>

{menuItems.map((item) => {
          return <Link to={``} key={item.id}>{item.name}</Link>;
        })}

*/
