import React from "react";
import "./Buttons.scss";

export const DefaultButton = ({ click, text }) => {
  return <button className="default-button" onClick={click}>{text}</button>;
};

export const FormButton = ({ click, text, type }) => {
  return (
    <button type={type} className="grey-button">
      {text}
    </button>
  );
};
