import React from "react";
import "./Card.scss";

const Card = ({ className, title, description, ima }) => {
  return (
    <div className={className}>
      <img src={ima} />

      <div className="card-col-1"></div>
      <div className="card-col-3"></div>
      <div className="card-row-1"></div>
      <div className="card-row-3">
        <p>{title}</p>
      </div>
    </div>
  );
};

export default Card;
