import React from "react";
import "./GridHomePresentation.scss";

export const GridHomePresentation = () => {
  return (
    <section className="grid-home-presentation-section">
      <div className="one item-container">
        <h2>WELCOME TO WOODCO BUILDING</h2>
        <p>
          At Woodco Building we ensure that your project <br /> is built with
          quality and care to ensure
          <br /> you many years of enjoyment
          <br /> from Small Extensions, Renovations,
          <br /> Second Storey Additions and Decks.
          <br /> We are specialists with over 10 years experience
          <br /> in home renovations, first floor extensions,
          <br /> including kitchen and bathroom renovations
        </p>
      </div>
      <div className="two">
        <img
          className="image-grid-home"
          src="/images/home3.jpg"
          alt="image of building comedor"
        />
      </div>
      <div className="three">
        <h2>Mission</h2>
        <p>
          Develop construction projects based on business
          <br />
          principles of high social responsibility with qualified
          <br />
          human capital, always seeking customer satisfaction,
          <br />
          economic growth and the improvement of society.
        </p>
      </div>

      <div className="four">
        {" "}
        <img
          className="image-grid-home"
          src="/images/home2.jpg"
          alt="image of building comedor"
        />
      </div>
      <div className="five">
        <h2>Vision</h2>
        <p>
          Consolidate ourselves as a leading company at the
          <br />
          national level in the construction sector with projects
          <br />
          that meet the established times, with high
          <br />
          quality and with total respect for the environment,
          <br />
          thus managing to export our experience in our area.
        </p>
      </div>
      <div className="six">
        <img
          className="image-grid-home"
          src="/images/home4.jpg"
          alt="image of building comedor"
        />
      </div>
    </section>
  );
};
