export const servicesList = [
  {
    id: "card-1",
    ima: "/images/renovations.jpg",
    description: "",
    title: "Renovations",
  },
  {
    id: "card-2",
    ima: "/images/extensions.jpg",
    description: "",
    title: "Extensions",
  },
  {
    id: "card-3",
    ima: "/images/bathroom.jpg",
    description: "",
    title: "Bathrooms",
  },
  {
    id: "card-4",
    ima: "/images/contactBanner.jpg",
    description: "",
    title: "Granny flats",
  },
  {
    id: "card-5",
    ima: "/images/home1.jpg",
    description: "",
    title: "New builds",
  },
];
