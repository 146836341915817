import React, { useEffect } from "react";
import "./ServicesDescriptionParagraph.scss";

export const ServicesDescriptionParagraph = ({ title, text }) => {
  useEffect(() => {
    let textSplit = text.split(".");
  }, []);

  return (
    <div className="services-paragraph-container">
      <div className="services-paragraph-title-container">
        <h2 className="service-paragraph-title">{title}</h2>
        <p className="service-paragraph-mini-line">-</p>
      </div>
      <div className="services-description-separator">
        <span className="line-services-description"></span>
      </div>
      <div className="services-paragraph-description-container">
        <p className="services-paragraph-text">{text}</p>
      </div>
    </div>
  );
};
