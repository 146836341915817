import React from "react";
import "./GridGallery.scss";
import { data } from "./GridGalleryData";

export const GridGallery = () => {
  return (
    <section className="gallery-photo-container">
      {data.map((image) => {
        return (
          <div
            key={image.id}
            className="photo-gallery"
            style={{
              background: `url(${image.ima})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "bottom",
            }}
          >
            {/* <img className="gallery-img" src={image.ima} /> */}
          </div>
        );
      })}
    </section>
  );
};
