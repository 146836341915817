import React from "react";
import "./Footer.scss";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div></div>
        <div>
          <img
            className="white-logo-icon"
            src="/icons/woodcoWhiteLogo.png"
            alt="logo-footer"
          />
        </div>
        <div>
          <h3>Our Available Hours</h3>
          <p>Monday - Saturday 7 a.m - 3 p.m</p>
          <h3>Builders licence number</h3>
          <p>No. 346338C</p>
          <p>ABN: 87625013523</p>
          <p>ACN: 625 013 523</p>
        </div>
        <div>
          <h3>Navigation Map</h3>
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "#8A8478" : "white",
              textDecoration: isActive ? "underline" : "none",
            })}
            className="menu-navbar-text link-menu"
            to="/"
          >
            <p>Home</p>
          </NavLink>
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "#8A8478" : "white",
              textDecoration: isActive ? "underline" : "none",
            })}
            className="menu-navbar-text link-menu"
            to="/"
          >
            <p>About Us</p>
          </NavLink>
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "#8A8478" : "white",
              textDecoration: isActive ? "underline" : "none",
            })}
            className="menu-navbar-text link-menu"
            to="/"
          >
            <p>Services</p>
          </NavLink>
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "#8A8478" : "white",
              textDecoration: isActive ? "underline" : "none",
            })}
            className="menu-navbar-text link-menu"
            to="/"
          >
            <p>Gallery</p>
          </NavLink>
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "#8A8478" : "white",
              textDecoration: isActive ? "underline" : "none",
            })}
            className="menu-navbar-text link-menu"
            to="/"
          >
            <p>Contact Us</p>
          </NavLink>
        </div>

        <div>
          <h3>Contact Us</h3>
          <p>48/6 Herbert Street, St Leonards,</p>

          <p>2065, Ryde, NSW</p>
          <p>87 625 013 523</p>
        </div>
        <div>
          <img className="nsw-logo" alt="nsw-logo" src="/logos/nsw-logo.png" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "20%",
            }}
          >
            <a
              href="https://www.instagram.com/woodco.building/?hl=en"
              target="_blank"
            >
              <img
                alt="instagram-icon"
                src="/icons/instagram.png"
                width={40}
                height={40}
              />
            </a>

            <a
              href="https://www.facebook.com/WoodCoBuildingPtyLtd"
              target="_blank"
            >
              <img
                alt="facebook-icon"
                src="/icons/facebook.png"
                width={40}
                height={40}
                style={{ backgroundColor: "white", borderRadius: "8px" }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <p className="sub-footer-text">
          Woodco Building. All Rights Reserved. Herbert Street | Website Agency
        </p>
      </div>
    </>
  );
};
