import React from "react";
import "./BannerPresentation.scss";

export const BannerPresentation = ({ text, ima }) => {
  return (
    <section className="banner-presentation-container">
      <div
        className="image-container-banner-presentation"
        style={{
          background: `url(${ima})`,
          backgroundSize: "cover",
          backgroundPositionY: "center",
        }}
      >
        {/* <img className="banner-image" src={ima} alt={text + "banner image"} /> */}
      </div>
      <div className="banner-presentation-title-container">
        <h2 className="banner-presentation-title">{text}</h2>
      </div>
    </section>
  );
};
