import React from "react";
import { GalleryBanner } from "../components/GalleryBanner/GalleryBanner";
import { GridGallery } from "../components/GridGallery/GridGallery";

export const Gallery = () => {
  return (
    <div>
      <GalleryBanner ima="/images/galleryBanner.jpg" text="" />
      <GridGallery />
    </div>
  );
};
