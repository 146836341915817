import React from "react";
import "./App.scss";
import { Layout } from "antd";

/* PAGES */
import { Home } from "./pages/Home/Home";
import { About } from "./pages/About/About";
import { Contact } from "./pages/Contact/Contact";
import { Gallery } from "./pages/Gallery/Gallery";

import { Services } from "./pages/Services/Services";

/* Components */
import { NavBar } from "./components/NavBar/NavBar";
import { Footer } from "./components/Footer/Footer";

/* React Router */
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./fonts/gadugi-bold.ttf";
import "./fonts/gadugi-normal.ttf";
import "./fonts/gadugi-gras.ttf";

/* */

const { Content } = Layout;

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <section>
          <NavBar />
        </section>
        <Content>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/Contact-us" element={<Contact />} />
          </Routes>
        </Content>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
