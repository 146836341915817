import React from "react";
import "./CardLong.scss";

const CardLong = () => {
  return (
    <div className="card-long">
      <img className="card-long-ima-one" src="/images/blackComedor.jpg" />
      <img className="card-ima-two" src="/images/viewOutside.jpg" />
      <div className="card-col-1"></div>
      <div className="card-col-3"></div>
      <div className="card-row-1"></div>
      <div className="card-row-4"></div>
    </div>
  );
};

export default CardLong;
