import React from "react";
import { Carousel } from "antd";
import "./BigSectionSlider.scss";
import { DefaultButton } from "../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";

//<img className="carousel-content-style" src="/images/home1.jpg" />

export const BigSectionSlider = () => {
  return (
    <section className="carousel-section">
      <Carousel autoplay>
        <div
          className="carousel-content-style-1"
          style={{ background: "/images/home1.jpg" }}
        ></div>
      </Carousel>
      <div className="call-to-us-container">
        <div className="button-relative">
          <h2>Renovations</h2>
          <p>
            Do you want to add an ensuite? Open up a living space so your
            kitchen flows through to your living space?
          </p>
          <Link style={{ color: "white" }} to="/Contact-us">
            <DefaultButton text="Talk With Us" click={() => {}} />
          </Link>
        </div>
        
      </div>
      <img className="scroll-icon" src="/icons/Logo_Scroll_Woodco.png"  />
    </section>
  );
};
