import React, { useState } from "react";
import "./MobileMenu.scss";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const MobileMenu = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleShowMobile = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  if (showMobileMenu === false) {
    return (
      <img
        className="mobile-menu-burger"
        onClick={() => handleShowMobile()}
        alt="mobile-burger-menu"
        src="/icons/burger-white.svg"
      />
    );
  } else {
    return (
      <ul className="mobile-menu-show">
        <NavLink
          style={({ isActive }) => ({
            color: isActive ? "#8A8478" : "black",
            textDecoration: isActive ? "underline" : "none",
          })}
          className="menu-navbar-text link-menu"
          to="/"
          onClick={() => handleShowMobile()}
        >
          Home
        </NavLink>
        <NavLink
          style={({ isActive }) => ({
            color: isActive ? "#8A8478" : "black",
            textDecoration: isActive ? "underline" : "none",
          })}
          className="menu-navbar-text link-menu"
          to="/About"
          onClick={() => handleShowMobile()}
        >
          About Us
        </NavLink>
        <NavLink
          style={({ isActive }) => ({
            color: isActive ? "#8A8478" : "black",
            textDecoration: isActive ? "underline" : "none",
          })}
          className="menu-navbar-text link-menu"
          to="/Services"
          onClick={() => handleShowMobile()}
        >
          Services
        </NavLink>
        <HashLink onClick={() => handleShowMobile()} className="menu-navbar-text link-menu" to="/services#gallery">
          Gallery
        </HashLink>
        <NavLink
          style={({ isActive }) => ({
            color: isActive ? "#8A8478" : "black",
            textDecoration: isActive ? "underline" : "none",
          })}
          className="menu-navbar-text link-menu"
          to="/Contact-Us"
          onClick={() => handleShowMobile()}
        >
          Contact Us
        </NavLink>
      </ul>
    );
  }
};

export default MobileMenu;
