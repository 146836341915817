import React from "react";
import { Carousel } from "antd";
import "./SectionSlider.scss";

export const SectionSlider = () => {
  const sliders = [
    {
      id: "1",
      title: "Our History + 10 years",
      imaRoute: "/images/aboutSlider1.jpg",
      p1: "Woodco Building is led by Director Raffaele Sapio a 10 year veteran in the Construction industry.",
      p2: "Raffaele is a dynamic leader responsible for all of the companies projects, offering a hands-on approach for all client relationships.",
      p3: "His expertise is being able to deliver exceptional quality construction works within program and on budget.",
      p4: "",
    },
    {
      id: "2",
      title: "WOODCO BUILDING",
      imaRoute: "/images/aboutSlider2.png",
      p1: "Our name Woodco encapsulates our strength, vigour and consistency in every project that we undertake.",
      p2: "Woodco Building is one of Sydney’s leading contractors for Hospitality, Residential, Commercial refurbishment and design.",
      p3: "Woodco Building is one of Sydney’s leading contractors for Hospitality, Residential, Commercial refurbishment and design.",
      p4: "These are not just assertions, they are built into our contractual structure with our clients. You shouldn’t just expect excellence, you should rely on it.",
    },
    {
      id: "3",
      title: "WOODCO BUILDING",
      imaRoute: "/images/aboutSlider3.jpg",
      p1: "We offer commercial businesses both large and small the most reliable and efficient solution to redesigning and revitalising office space, restaurants and other spaces.",
      p2: "We offer commercial businesses both large and small the most reliable and efficient solution to redesigning and revitalising office space, restaurants and other spaces.",
      p3: "We are able to ensure that designs are feasible and quality control is maintained, minimising disruption and inconvenience for our clients.",
      p4: "",
    },
  ];

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <section className="about-carousel-section">
      <div>
        <Carousel
          afterChange={onChange}
          dots={{ color: "black" }}
          arrows={true}
          draggable={true}
          prevArrow={
            <span>
              <p>atras</p>
            </span>
          }
          nextArrow={<span>adelante</span>}
          className="about-carousel-carousel"
        >
          {sliders.map((item) => {
            return (
              <div key={item.id} className="about-carousel-content-style">
                <div className="about-carousel-one-container">
                  <div className="one">
                    <h2 className="vertical-title">{item.title}</h2>
                  </div>
                  <div className="two">
                    <img
                      className="Image-carousel-content"
                      src={item.imaRoute}
                    />
                  </div>
                </div>

                <div className="about-carousel-two-container">
                  <p className="black-regular-text">{item.p1}</p>
                  <p className="black-regular-text">{item.p2}</p>
                  <p className="black-regular-text">{item.p3}</p>
                  <p className="black-regular-text">{item.p4}</p>
                </div>
                <div className="four"></div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

/*

<div className="carousel-content-style">
          <div className="one">
            <h2>Our History + 10 years</h2>
          </div>
          <div className="two">
            <img
              className="Image-carousel-content"
              src="/images/aboutSlider1.jpg"
            />
          </div>
          <div className="three">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Consequatur, aliquam. Similique aperiam asperiores impedit veniam
              ratione quaerat, officiis, cumque nesciunt culpa eligendi beatae!
              Et consequuntur possimus nemo pariatur maiores tenetur.
            </p>
          </div>
        </div>
        <div className="carousel-content-style">
          <h2 className="one">Our History + 10 years</h2>
          <img
            className="Image-carousel-content two"
            src="/images/aboutSlider2.png"
          />
          <p className="three">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Consequatur, aliquam. Similique aperiam asperiores impedit veniam
            ratione quaerat, officiis, cumque nesciunt culpa eligendi beatae! Et
            consequuntur possimus nemo pariatur maiores tenetur.
          </p>
        </div>
        <div className="carousel-content-style">
          <h2 className="one">Our History + 10 years</h2>
          <img
            className="Image-carousel-content two"
            src="/images/aboutSlider3.jpg"
          />
          <p className="three">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Consequatur, aliquam. Similique aperiam asperiores impedit veniam
            ratione quaerat, officiis, cumque nesciunt culpa eligendi beatae! Et
            consequuntur possimus nemo pariatur maiores tenetur.
          </p>
        </div>*/
